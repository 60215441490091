export const PATHS_WITH_FLOATING_HEADERS = [
  '/',
  '/referrals',
  '/home-appraisal',
  '/exp-specialized-divisions',
  '/sell',
  '/buy',
  '/explore-exp',
  '/join-exp',
  '/join-exp-us',
  '/join-exp-ca',
  '/my-link-my-lead',
  '/income',
  '/relocation',
  '/reo',
  '/inservice-life',
];

export const PATHS_WITH_SCROLLED_HEADERS = [
  '/relocation',
  '/reo',
];