import { MouseEvent } from 'react';
import { buildClassName } from 'utils/build-class-name';
import styles from './style.module.scss';
import { useIsMobile } from 'hooks/use-size-class';

export type SquareIconProps = {
  onClick?: (event: MouseEvent<Element>) => void;
  testId?: string;
  size?: 'xs' | 'small' | 'medium' | 'large' | 'mobile';
  isHoverable?: boolean;
  isExpTheme?: boolean;
  bgColor?: 'dark' | 'light';
};

type SquareIconWrapperProps = SquareIconProps & {children: React.ReactNode};

export default function SquareWrapper({ 
  children, 
  onClick, 
  testId,
  size = 'small', 
  bgColor = 'dark', 
  isHoverable = false, 
  isExpTheme = false }: 
  SquareIconWrapperProps) {
  const isMobile = useIsMobile();
  return (
    <div 
      className={buildClassName(styles.component, styles[size], styles[bgColor], isExpTheme && styles['exp-theme'])} 
      onClick={onClick}
      data-testid={testId}>
      { isHoverable && !isMobile ?
        <div className={buildClassName(styles['hover-wrapper'], !isExpTheme && styles['zoocasa-theme'])}>
          {children}
        </div>
        :
        <>{children}</>
      }
    </div>
  );
}