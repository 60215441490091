import { useThemeContext } from 'contexts';
import { useCallback } from 'react';
import { ThemeNames } from 'types/themes';
import { trackEvent } from 'utils/google-tag-manager';

export const useTrackExpOnlyEvents = () => {
  const { themeName } = useThemeContext();
  
  const isExpTheme = themeName !== ThemeNames.ZOOCASA;
  
  const trackExpEvent = useCallback((eventName: string) => {
    if (!isExpTheme) return;
    trackEvent(eventName);
  }, [isExpTheme]);
  
  return { trackExpEvent };
};