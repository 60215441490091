import React from 'react';
import styles from './style.module.scss';
import { buildClassName } from 'utils/build-class-name';
import { testIds } from 'constants/test-constants';
import Link from 'components/link';

//#region Types
export type MenuItem = {
  /** The id of the item. This will be used as the argument for the onClick handler. */
  id: string;
  /** The label of the item. This should be a translation key. */
  label: string;
  /** The href of the item. */
  href: string;
  /** The onClick handler for the item.*/
  onClick?: () => void;
  /** Whether the item should open in a new tab. */
  newTab?: boolean;
}

export interface DropdownProps {
  items: MenuItem[];
  className?: string;
}
//#endregion

export default function Dropdown({ items, className }: DropdownProps) {
  return (
    <div className={buildClassName(styles.component, className, styles.exp)}>
      <ul className={styles['menu-items']}>
        {items.map((item, index) => {
          if (item?.onClick) {
            return (
              <li key={index}>
                <a onClick={item.onClick} data-testid={item.id + testIds.menuDropdownOption}>{item.label}</a>
              </li>
            );
          } else {
            return (
              <li key={index}>
                <Link href={item.href} target={item.newTab && '_blank'} testId={`${item.id}-${testIds.menuDropdownOption}`}>
                  {item.label}
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}
