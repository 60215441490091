import TextInput from '@zoocasa/node-kit/components/controls/text-input';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import Button from 'components/control/button';
import FilterIcon from 'components/icon/filter-icon';
import { homepageIds, searchFilterIds, testIds } from 'constants/test-constants';
import { Filter } from 'contexts/preferences/listing-params/types';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import { SEARCH_OPTION_HOME_APPRAISAL } from 'themes/themeConfig';
import { PartialDeep } from 'type-fest';
import { buildClassName } from 'utils/build-class-name';
import { searchOptions } from 'utils/select-options';
import { useSearchBar } from '../useSearchBar';
import SearchOptionButtonGroup from './search-option-button-group';
import styles from './style.module.scss';

export type AdvancedSearchProps = {
  showsSearchOptions?: boolean;
  isFilterButtonHidden?: boolean;
  isInPanel?: boolean;
  testId?: string;
  hideSearchPanel?: () => void;
  onValueChange?: (filters: PartialDeep<Filter>, changedFilter?: string) => void;
  onFilterButtonClick?: () => void;
}

const AdvancedSearch = ({ showsSearchOptions = false, isInPanel = false, testId, isFilterButtonHidden = false, onFilterButtonClick }: AdvancedSearchProps) => {
  const { 
    theme,
    isMobile,
    isLocationDropdownActive, 
    SuggestedLocationDropdown,
    searchPlaceholder, 
    isHomeAppraisal,
    isExpTenant,
    disablePopularSearches,
    currentSearchOptions,
    searchPredictions:{
      setActivePrediction,
      isLoadingSearchPredictions, 
      searchPredictions, 
      searchAgentPredictions, 
      isTyping,
    },
    locationQuery,
    setLocationQuery,
    doOnSearchResultClicked,
    doOnSearchButtonClicked,
    doOnSearchOptionChanged,
    hideSearchSuggestions,
    doOnSearchBarClicked,
  } = useSearchBar({ isInPanel });


  return (
    <div 
      className={
        buildClassName(styles.component, 
          isExpTenant && styles['exp-theme'], 
          isHomeAppraisal && styles['home-appraisal'])
      } 
      data-testid={testId ? testId : isMobile ? testIds.advancedSearchMobile : homepageIds.homepageSearchbar}>
      {showsSearchOptions && theme.searchOptions.length>1 &&
      <div className={styles['search-options']}>
        <SearchOptionButtonGroup
          isExpTheme={isExpTenant}
          options={searchOptions(theme.searchOptions)}
          onValueChange={doOnSearchOptionChanged}
          value={currentSearchOptions}
        />
      </div>
      }
      <div className={buildClassName(styles['search-wrapper'], isExpTenant && styles['exp-wrapper'])} ref={useOutsideClickHandler(hideSearchSuggestions)}>
        <TextInput
          name="search"
          placeholder={searchPlaceholder}
          value={(capitalizeWords(locationQuery))}
          onClick={doOnSearchBarClicked}
          onValueChange={setLocationQuery}
          autoComplete='off'
          autoFocus={isInPanel}
          data-isopen={isLocationDropdownActive.toString()}
          data-testid={isMobile ? testIds.mobileSearchField : homepageIds.homepageSearchField}
        />
        {SuggestedLocationDropdown &&
        <SuggestedLocationDropdown
          isLoading={isLoadingSearchPredictions}
          searchPredictions={searchPredictions}
          setActivePrediction={setActivePrediction}
          onClick={doOnSearchResultClicked}
          isActive={isLocationDropdownActive}
          className={styles['suggested-location-dropdown']}
          isAdvancedSearch={true}
          locationQuery={locationQuery}
          isTyping={isTyping}
          disablePopularSearches={disablePopularSearches}
          searchAgentPredictions={searchAgentPredictions}
        />
        }
        <div className={styles['search-button-container']}>
          {!isFilterButtonHidden && currentSearchOptions !== SEARCH_OPTION_HOME_APPRAISAL &&
          <Button label="Filter" Icon={FilterIcon} theme="secondary" onClick={onFilterButtonClick} testId={searchFilterIds.searchbarFilterButton} />
          }
          <Button label="Search" theme="primary" onClick={doOnSearchButtonClicked} testId={isMobile ? testIds.mobileSearchButton : homepageIds.homepageSearchButton} />
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearch;