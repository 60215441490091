import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { buildClassName } from 'utils/build-class-name';
import Link from 'components/link';
import { useUserContext } from 'contexts/user';
import { IModalContext, ModalContext } from 'contexts/modal';
import MenuButton from 'components/header/menu-button';
import ThemedIcon from 'components/themed-icon';
import styles from './style.module.scss';
import { headerIds, testIds } from 'constants/test-constants';
import { useIsMobile } from 'hooks/use-size-class';
import { useFeaturesContext } from 'contexts';
import Button from 'components/control/button';
import UserIcon from 'components/icon/user-icon';
import { PATHS_WITH_FLOATING_HEADERS, PATHS_WITH_SCROLLED_HEADERS } from 'constants/ui-headers';
import AdvancedSearch from 'components/search-bar/advanced-search';
import SquareSearchIcon from 'components/icon/square/search-icon';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import { usePreferencesContext } from 'contexts/preferences';
import { useTrackExpOnlyEvents } from 'hooks/use-track-exp-only-events';
import { GTM_EXP_FIND_YOUR_HOME_CLICK_HOME_PAGE, GTM_EXP_FIND_YOUR_HOME_CLICK_UNIVERSAL } from 'constants/events';
import Dropdown, { MenuItem } from '../dropdown-v2';
import { useTranslation } from 'react-i18next';
import { SiteNameSpace } from 'constants/locale';


import type { HeaderConfig } from 'tenants/tenants';

const Header = ({ config }: { config?: HeaderConfig }) => {
  const { t, ready } = useTranslation(SiteNameSpace.ExpInService);
  const { mainButtonCopy, authButtonCopy, hasJoinExpPage, authMenuItems, menuItems } = config;
  const { isAuthenticated, user, isCrawler, signOut } = useUserContext();
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { isSearchPanelOpen, setIsSearchPanelOpen } = useFeaturesContext();
  const { setIsMapSearchOpen, isMapSearchOpen } = usePreferencesContext();
  const [menuDropdownIsActive, setMenuDropdownIsActive] = useState(isCrawler);
  const [userDropdownIsActive, setUserDropdownIsActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const isMobile = useIsMobile();
  const router = useRouter();
  const { trackExpEvent } = useTrackExpOnlyEvents();
  const isFloating = PATHS_WITH_FLOATING_HEADERS.includes(router.pathname);
  const useScrolledHeader = PATHS_WITH_SCROLLED_HEADERS.includes(router.pathname);
  const isInserviceBlogWithPageQuery = router.asPath.includes('/inservice-life?page=');

  const isAgentSearch = router.pathname === '/agents-search';
  const isHomePage = router.pathname === '/';
  const isSearchPage = router.pathname && router.pathname.startsWith('/search');
  const isAreaPage = router.pathname == '/[...dynamic]';
  const isJoinExp = hasJoinExpPage && router.pathname === '/join-exp';

  const searchSectionOutsideClickHandler = useOutsideClickHandler<HTMLDivElement>(() => { setIsSearchPanelOpen(false); setIsMapSearchOpen(false); });

  const toggleMenuDropdown = () => {
    setMenuDropdownIsActive(prev => !prev);
  };
  const toggleUserDropdown = () => {
    setUserDropdownIsActive(prev => !prev);
  };

  const generateAuthUserLabel = () => {
    if (user) {
      if (user.firstName || user.lastName) {
        return `${(user?.firstName || '').slice(0, 1)}${(user?.lastName || '').slice(0, 1)}`;
      }
      return user?.email?.slice(0, 1);
    }
    return t(authButtonCopy);
  };
  const authUserLabel = generateAuthUserLabel();

  const handleScroll = () => setIsScrolled(window.scrollY > 40);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleSearch = () => {
    if (isMapSearchOpen) {
      setIsMapSearchOpen(false);
    } else {
      const newValue = !isSearchPanelOpen;
      setIsSearchPanelOpen(newValue);
    }
  };

  const SearchOrAreaPageButton = () => (
    <div className={styles.search} ref={searchSectionOutsideClickHandler}>
      <Button
        Icon={isMobile && SquareSearchIcon}
        onClick={toggleSearch}
        className={buildClassName(styles.auth, isMobile && styles.user, (isSearchPanelOpen || isMapSearchOpen) && styles.active)}
      />
      {isSearchPanelOpen && AdvancedSearch && (
        <div className={styles.panel}>
          <AdvancedSearch isInPanel={true} isFilterButtonHidden={true} hideSearchPanel={() => setIsSearchPanelOpen(false)} />
          <div className={styles['full-screen-search']} />
        </div>
      )}
    </div>
  );

  const JoinExpContent = () => (
    <Link href="https://join.exprealty.com/contact/" className={styles['find-your-home']}>
      <Button tabIndex={-1} label="Contact Us" className={styles.search} />
    </Link>
  );

  const DefaultContent = () => (
    <Link onClick={() => {
      if (isHomePage) {
        trackExpEvent(GTM_EXP_FIND_YOUR_HOME_CLICK_HOME_PAGE);
        trackExpEvent(GTM_EXP_FIND_YOUR_HOME_CLICK_UNIVERSAL);
      } else {
        trackExpEvent(GTM_EXP_FIND_YOUR_HOME_CLICK_UNIVERSAL);
      }
    }}
    href={'/search'} className={styles['find-your-home']}>
      {isMobile ?
        <Button
          tabIndex={-1}
          Icon={SquareSearchIcon}
          className={buildClassName(styles.auth, styles.user)}
        />
        :
        <Button
          tabIndex={-1}
          label={t(mainButtonCopy)}
          className={styles.search}
        />
      }
    </Link>
  );

  const HeaderButton = () => {
    if ((isSearchPage || isAreaPage) && isMobile) {
      return <SearchOrAreaPageButton />;
    } else if (isJoinExp) {
      return <JoinExpContent />;
    } else {
      return <DefaultContent />;
    }
  };

  const menuItemsConfig = menuItems.map(menuItem => {
    const item: MenuItem = {
      label: t(menuItem.label),
      href: menuItem.link,
      id: menuItem.id,
      newTab: menuItem.newTab,
    };
    return item;
  });
  const handleSignOut = signOut;
  const authMenuItemsConfig = authMenuItems.map(menuItem => {
    const item: MenuItem = {
      label: t(menuItem.label),
      href: menuItem.link,
      id: menuItem.id,
      onClick: menuItem?.signOut ? handleSignOut : undefined,
    };
    return item;
  });

  if (!ready) {
    return null;
  }

  return (
    <div className={buildClassName(styles.component, isFloating && !isInserviceBlogWithPageQuery && styles.home, isAgentSearch && styles['agent-search'], (isScrolled || useScrolledHeader || isInserviceBlogWithPageQuery) && styles.scrolled)} data-testid={headerIds.header}>
      <div className={buildClassName(styles['layout-container'])}>
        <div className={buildClassName(styles['logo-wrapper'])} data-testid={headerIds.zoocasaHeaderLogo}>
          <Link href="/" as="/" className={styles.logo} rel="noreferrer"><ThemedIcon /></Link>
        </div>
        <div className={styles.navigation}>
          <HeaderButton />

          {isAuthenticated
            ?
            <MenuButton onClick={toggleUserDropdown} closeMenu={() => setUserDropdownIsActive(false)}>
              <span data-testid={headerIds.loggedInUser}>{authUserLabel} {userDropdownIsActive && Dropdown && <Dropdown items={authMenuItemsConfig} />}</span>
            </MenuButton>
            :
            <Button
              label={!isMobile ? authUserLabel : ''}
              Icon={isMobile && UserIcon}
              onClick={() => openModal('login-registration')}
              className={buildClassName(styles.auth, isMobile && styles.user)}
            />
          }
          <MenuButton className={styles.menu} testId={testIds.menuButton} onClick={toggleMenuDropdown} closeMenu={() => setMenuDropdownIsActive(false)}>
            <>
              <div className={styles['bar-container']} id="menu">
                <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
                <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
              </div>
              {menuDropdownIsActive && Dropdown && <Dropdown items={menuItemsConfig} />}
            </>
          </MenuButton>
        </div>
      </div>
    </div>
  );
};

export default Header;
