import React, { MouseEvent } from 'react';
interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
  testId?: string;
}

const SearchIcon = ({ className, onClick, testId }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32" className={className} onClick={onClick} data-testid={testId}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path id="path-1" d="M13.874.04C6.224.04 0 6.263 0 13.914c0 7.65 6.224 13.874 13.874 13.874 2.34 0 4.638-.555 6.58-1.625.156.187.329.36.516.515l3.964 3.964a4.043 4.043 0 105.708-5.708l-3.964-3.964a3.964 3.964 0 00-.634-.515c1.07-1.943 1.744-4.202 1.744-6.58C27.788 6.223 21.565 0 13.914 0l-.04.04zm0 3.964c5.51 0 9.91 4.4 9.91 9.91 0 2.616-.95 5.034-2.616 6.818l-.119.12c-.187.155-.36.327-.515.514-1.744 1.586-4.123 2.498-6.7 2.498-5.51 0-9.91-4.4-9.91-9.91s4.4-9.91 9.91-9.91l.04-.04z"></path>
      </g>
    </svg>
  );
};

export default SearchIcon;
