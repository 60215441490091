import TextInput from '@zoocasa/node-kit/components/controls/text-input';
import Button from 'components/control/button';
import { homepageIds, searchFilterIds } from 'constants/test-constants';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import { buildClassName } from 'utils/build-class-name';
import { useSearchBar } from '../useSearchBar';
import styles from './style.module.scss';

interface Props {
  fullWidth?: boolean;
  isInPanel?: boolean;
}

const ImmersiveSearch = ({ fullWidth = false, isInPanel = false }: Props) => {
  const { 
    isLocationDropdownActive, 
    SuggestedLocationDropdown,
    textInputRef, 
    searchPlaceholder, 
    isHomeAppraisal,
    isExpTenant,
    disablePopularSearches,
    searchPredictions:{
      setActivePrediction,
      isLoadingSearchPredictions, 
      searchPredictions, 
      searchAgentPredictions, 
      isTyping,
    },
    locationQuery, 
    setLocationQuery,
    doOnSearchResultClicked,
    hideSearchSuggestions,
    doOnSearchBarClicked,
  } = useSearchBar({ isInPanel });
  return (
    <div 
      className={buildClassName(styles.component, styles['exp-theme'], isHomeAppraisal && styles['home-appraisal'], fullWidth && styles['full-width'])} 
      data-testid={homepageIds.homepageSearchbar}>      
      <div className={styles['exp-wrapper']} ref={useOutsideClickHandler(() => hideSearchSuggestions())}>
        {<TextInput
          name="search-fake"
          placeholder={searchPlaceholder}
          onClick={() => {
            doOnSearchBarClicked && doOnSearchBarClicked();
            textInputRef.current?.focus();
          }}
          autoComplete='off'
          data-isopen={isLocationDropdownActive.toString()}
          data-testid={searchFilterIds.searchbar+'test'}
        />}
        {(isLocationDropdownActive && SuggestedLocationDropdown) && <div className={styles['screen-take-over']} onClick={hideSearchSuggestions}>
          <div className={styles['full-screen-search-wrapper']} onClick={e => e.stopPropagation()}>
            <Button label="X" className={styles.close} theme="primary" onClick={hideSearchSuggestions}/>
            <div className={buildClassName(styles['search-wrapper'], isExpTenant && styles['exp-wrapper'])}>
              <TextInput
                ref={textInputRef}
                name="search"
                placeholder={searchPlaceholder}
                value={locationQuery}
                onValueChange={setLocationQuery}
                autoComplete='off'
                autoFocus={true}
                data-isopen={isLocationDropdownActive.toString()}
                data-testid={searchFilterIds.searchbar+'test'}
              />
            </div>
            <SuggestedLocationDropdown
              fullWidth
              isLoading={isLoadingSearchPredictions}
              searchPredictions={searchPredictions}
              setActivePrediction={setActivePrediction}
              onClick={doOnSearchResultClicked}
              isActive={isLocationDropdownActive}
              className={styles['suggested-location-dropdown']}
              isAdvancedSearch={true}
              locationQuery={locationQuery}
              isTyping={isTyping}
              disablePopularSearches={disablePopularSearches}
              searchAgentPredictions={searchAgentPredictions}
            />
          </div>
        </div>}
      </div>
    </div>
  );
};

export default ImmersiveSearch;