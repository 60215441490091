import {
  useFeaturesContext,
  usePreferencesContext,
  User,
  useThemeContext,
  useUserContext,
} from 'contexts';
import pushToRoute from 'utils/push-to-route';
import { getListingById } from 'data/listing';
import { createSearchPredictionModel, MapType } from 'data/search-predictions';
import useLocationSearch, { searchProvider } from 'hooks/use-location-search';
import useMapLocationChanger from 'hooks/use-map-location-changer';
import { useIsMobile } from 'hooks/use-size-class';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SEARCH_OPTION_AGENTS, SEARCH_OPTION_BUY, SEARCH_OPTION_HOME_APPRAISAL, SEARCH_OPTION_RENT, SEARCH_OPTION_SOLD } from 'themes/themeConfig';
import { ThemeNames } from 'types/themes';
import HomeAppraisalStorage from 'utils/appraisal-storage';
import { findPlaceByPlaceId } from 'utils/google-maps/geoLocator';
import { isCanadianProvinceCode, ProvinceOrStateCode } from 'utils/province_or_state';

import type { SearchAgentPrediction, SearchSuggestions } from 'components/suggested-location-dropdown';
import type ListingParams from 'contexts/preferences/listing-params';
import type SearchPrediction from 'data/search-predictions';
import type { SearchOptions } from 'themes/themeConfig';
import { AGENT_SEARCH_PATH } from 'utils/agent-endpoint';
import { isExpTheme } from 'utils/tenant/which-tenant';
import { BUY_FILTER_OPTION, RENT_FILTER_OPTION, SOLD_FILTER_OPTION } from 'constants/area-map-page-filters';
import { AVAILABLE_STATUS, NOT_AVAILABLE_SOLD_STATUS } from 'contexts/preferences/listing-params';
import { AdvancedSearchProps } from './advanced-search';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_ALL_HEADER_SEARCH_BUTTON_CLICK, GTM_CLICK_APPRAISAL_GOOGLE_ADDRESS, GTM_CLICK_APPRAISAL_HOME_SEARCH_BAR, GTM_CLICK_APPRAISAL_HOME_SEARCH_RESULT, GTM_CLICK_APPRAISAL_LISTING_ADDRESS, GTM_CLICK_HOMEPAGE_SEARCH_BAR, GTM_CLICK_HOMEPAGE_SEARCH_BUTTON } from 'constants/events';

interface HandleLocationSearchProps {
  searchPrediction: SearchPrediction;
  listingParams: ListingParams;
  user: User | null;
  map?: MapType;
  addToRecentSearches: (searchPrediction: SearchPrediction) => void;
  setActivePrediction?: (searchPrediction: SearchPrediction) => void;
  themeName: ThemeNames;
}

type Props = AdvancedSearchProps & {
  isInPanel?: boolean;
}

export const handleSearchResult = ({ searchPrediction, listingParams, user, map, addToRecentSearches, setActivePrediction, themeName } : HandleLocationSearchProps) => {
  const prediction: SearchPrediction = !searchPrediction.transitionToPath ? createSearchPredictionModel(searchPrediction) : searchPrediction;
  setActivePrediction?.(prediction);
  prediction.transitionToPath(listingParams, themeName, map, user, addToRecentSearches);
};

const useSearchBar = ({ isInPanel = false, onValueChange }: Props) => {
  const { features, setIsSearchPanelOpen } = useFeaturesContext();
  const router = useRouter();
  const isHomePage = router.pathname === '/';
  const isHomeAppraisal = router.pathname && router.pathname.startsWith('/home-appraisal');
  const { user, siteLocation } = useUserContext();
  const { listingParams, addToRecentSearches, lastSearchLocation } = usePreferencesContext();
  const { theme, themeName } = useThemeContext();
  const [activePrediction, setActivePrediction] = useState<SearchPrediction | SearchSuggestions>();
  const [isLocationDropdownActive, setIsLocationDropdownActive] = useState(isInPanel);
  const [onSearchPage, setOnSearchPage] = useState(false);
  const isMobile = useIsMobile();
  const [locationQuery, setLocationQuery] = useState(lastSearchLocation ? lastSearchLocation.description: '');
  // When user clicks on search inputs and it transforms to full screen search it looses the search options state
  const [currentSearchOptions, setCurrentSearchOptions] = useState<SearchOptions>(isHomeAppraisal ? SEARCH_OPTION_HOME_APPRAISAL : SEARCH_OPTION_BUY);
  const [SuggestedLocationDropdown, setSuggestedLocationDropdown] = useState<any>();
  const { isLoadingSearchPredictions, searchPredictions, searchAgentPredictions, isTyping } = useLocationSearch(
    locationQuery,
    features.useNewSearch,
    features.useUsListings,
    features.useGoogleSearch ? searchProvider.GOOGLE : searchProvider.APPLE,
    currentSearchOptions
  );
  const isExpTenant = isExpTheme(themeName);
  const disablePopularSearches = currentSearchOptions.match(new RegExp(`${SEARCH_OPTION_AGENTS}|${SEARCH_OPTION_HOME_APPRAISAL}`, 'g'));
  const textInputRef = useRef<HTMLInputElement>(null); // Create a ref for the TextInput

  const getPlaceholder = (type: SearchOptions) => {
    const searchOption = theme.searchOptions.find(option => option.type === type);
    return searchOption?.placeholder(siteLocation, isMobile);
  };

  const map: MapType = useMapLocationChanger();

  const searchPlaceholder = getPlaceholder(currentSearchOptions);

  const hideSearchPanel = () => {
    setIsSearchPanelOpen(false);
  };

  const dismissSearchPanel = () => {
    hideSearchPanel();
    setIsLocationDropdownActive(false);
    setSuggestedLocationDropdown(null);
  };

  const doOnSearchOptionChanged = useCallback((value: SearchOptions) => {
    setCurrentSearchOptions(value);
    if (onValueChange) {
      if (value === SEARCH_OPTION_BUY) {
        onValueChange({ status: AVAILABLE_STATUS, rental: false }, BUY_FILTER_OPTION);
      } else if (value === SEARCH_OPTION_RENT) {
        onValueChange({ status: AVAILABLE_STATUS, rental: true }, RENT_FILTER_OPTION);
      } else if (value === SEARCH_OPTION_SOLD) {
        onValueChange({ status: NOT_AVAILABLE_SOLD_STATUS, rental: false }, SOLD_FILTER_OPTION);
      }
    } else {
      if (value === SEARCH_OPTION_BUY) {
        listingParams.setRental(false);
        listingParams.setStatus(AVAILABLE_STATUS);
      } else if (value === SEARCH_OPTION_RENT) {
        listingParams.setRental(true);
        listingParams.setStatus(AVAILABLE_STATUS);
      } else if (value === SEARCH_OPTION_SOLD) {
        listingParams.setRental(false);
        listingParams.setStatus(NOT_AVAILABLE_SOLD_STATUS);
      }
    }
  }, [listingParams, onValueChange]);

  const doOnSearchResultClicked = async (searchPrediction: SearchPrediction | SearchAgentPrediction) => {
    isHomePage && trackEvent(GTM_CLICK_HOMEPAGE_SEARCH_BUTTON);
    if (isHomeAppraisal) {
      trackEvent(GTM_CLICK_APPRAISAL_HOME_SEARCH_RESULT);
      const eventName = (searchPrediction.group === 'locations') ? GTM_CLICK_APPRAISAL_GOOGLE_ADDRESS : GTM_CLICK_APPRAISAL_LISTING_ADDRESS;
      trackEvent(eventName);
    }
    if (currentSearchOptions === SEARCH_OPTION_AGENTS) { // go to eXp agent search page
      const locationArray = (searchPrediction as SearchAgentPrediction).label.split(',');
      const trimmedLocationArray = locationArray.map(part => part.trim()); // '['Old Toronto', 'Toronto', 'ON']
      const city = trimmedLocationArray[trimmedLocationArray.length - 2];
      const provinceOrState = trimmedLocationArray[trimmedLocationArray.length - 1];
      const country = isCanadianProvinceCode(provinceOrState.toLowerCase() as ProvinceOrStateCode) ? 'CA' : 'US';
      router.push(`${AGENT_SEARCH_PATH}/?location=${city}%2C+${provinceOrState}&country=${country}`);
    } else if (currentSearchOptions === SEARCH_OPTION_HOME_APPRAISAL) {
      const isListingPrediction = searchPrediction.group == 'listings';
      if (isListingPrediction) {
        const listingId = (searchPrediction as SearchPrediction).id?.split('-')?.[1];
        const listing = await getListingById(listingId);
        HomeAppraisalStorage.setProperty(listing);
      } else { // Means we got location prediction from Google
        const data = await findPlaceByPlaceId((searchPrediction as SearchPrediction).id);
        if (data) HomeAppraisalStorage.setProperty(data);
      }
      router.push('/home-appraisal/property-details');
    } else {
      handleSearchResult({
        searchPrediction: searchPrediction as SearchPrediction,
        listingParams,
        user,
        map: onSearchPage ? map : undefined,
        addToRecentSearches,
        setActivePrediction,
        themeName: themeName as ThemeNames,
      });
    }
    dismissSearchPanel();
  };

  const doOnSearchButtonClicked = useCallback(() => {
    if (activePrediction && isLocationDropdownActive && locationQuery.length >= 3) {
      const isSearchSuggestion = (activePrediction as Record<string, unknown>)['group'] === undefined;
      if (isSearchSuggestion) {
        pushToRoute(activePrediction as SearchSuggestions, listingParams, router, themeName as ThemeNames, map);
      } else {
        doOnSearchResultClicked(activePrediction as SearchPrediction);
      }
      dismissSearchPanel();
    }
    else if (lastSearchLocation && locationQuery === lastSearchLocation.description) {
      doOnSearchResultClicked(lastSearchLocation as SearchPrediction);
    }
  }, [activePrediction, isLocationDropdownActive, locationQuery, lastSearchLocation, hideSearchPanel, listingParams, router, themeName, map, doOnSearchResultClicked]);


  const showSearchSuggestions = () => {
    if (isMobile) {
      setIsSearchPanelOpen(true);
    } else {
      setIsLocationDropdownActive(true);
    }
  };

  const hideSearchSuggestions = () => {
    setIsLocationDropdownActive(false);
  };

  useEffect(() => {
    // On mobile, the location search is hidden and the user only sees the advanced search. This logic is for if the user is on
    // the map page and they search for a location that has an area page, they should stay on the map instead of being redirected
    // to the area page.
    if (window.location.pathname.startsWith('/search')) {
      setOnSearchPage(true);
    } else {
      setOnSearchPage(false);
    }
    if (window.location.pathname.startsWith('/home-appraisal')) {
      setCurrentSearchOptions(SEARCH_OPTION_HOME_APPRAISAL);
    }
  }, []);

  useEffect(() => {
    if (isLocationDropdownActive && !SuggestedLocationDropdown) {
      setSuggestedLocationDropdown(dynamic(import('components/suggested-location-dropdown')));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocationDropdownActive]);

  const doOnSearchBarClicked = useCallback(() => {
    trackEvent(GTM_CLICK_ALL_HEADER_SEARCH_BUTTON_CLICK);
    isHomePage && trackEvent(GTM_CLICK_HOMEPAGE_SEARCH_BAR);
    isHomeAppraisal && trackEvent(GTM_CLICK_APPRAISAL_HOME_SEARCH_BAR);
    showSearchSuggestions();
  }, [isHomePage, isHomeAppraisal, showSearchSuggestions]);

  return {
    isMobile,
    theme,
    isHomeAppraisal,
    textInputRef,
    isExpTenant,
    SuggestedLocationDropdown,
    searchPlaceholder,
    disablePopularSearches,
    isLocationDropdownActive,
    currentSearchOptions,
    isHomePage,
    searchPredictions:{
      activePrediction,
      setActivePrediction,
      isLoadingSearchPredictions, 
      searchPredictions, 
      searchAgentPredictions, 
      isTyping,
    },
    locationQuery, 
    setLocationQuery,
    doOnSearchBarClicked,
    doOnSearchOptionChanged,
    doOnSearchResultClicked,
    doOnSearchButtonClicked,
    showSearchSuggestions,
    hideSearchSuggestions,
  };
};

export { useSearchBar };