import React, { useContext } from 'react';
import Link from 'components/link';
import { trackEvent } from 'utils/google-tag-manager';
import { FeaturesContext, IFeatures } from 'contexts/features';
import { IModalContext, ModalContext, useChat, useThemeContext, useUser, useUserContext } from 'contexts';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';
import { translate } from 'utils/translations';
import { ThemeNames } from 'types/themes';
import { useRouter } from 'next/router';
import { EXP_MENU_LINK_LABEL_MY_SEARCHES, EXP_MENU_LINK_LABEL_SIGN_IN } from 'themes/exprealtyCA';
import { buildClassName } from 'utils/build-class-name';
import { ReadonlyDeep } from 'type-fest';
import { openEqualWebMenu } from 'utils/accessibility';

export type DropdownProps = ReadonlyDeep<{
  items: {
    label: string;
    link?: string;
    isEmber?: boolean;
    newTab?: boolean;
    mobileOnly?: boolean;
  }[];
}>

export default function Dropdown({ items }: DropdownProps) {
  const { features } = useContext(FeaturesContext) as IFeatures;
  const { openChat } = useChat();
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { siteLocation, signOut } = useUser();
  const { themeName } = useThemeContext();
  const { isAuthenticated } = useUserContext();
  const router = useRouter();
  const isZoocasaTenant = themeName === ThemeNames.ZOOCASA;

  const handleAccessibilityClick = () => {
    openEqualWebMenu();
  };

  return (
    <div className={buildClassName(styles.component, !isZoocasaTenant && styles.exp)}>
      <ul className={styles['menu-items']}>
        {items.map((item, index) => {
          if (item.label === 'Sign Out') {
            return (
              <li key={index}>
                <a onClick={signOut} data-testid={item.label.replace(/\s+/g, '') + testIds.menuDropdownOption}>{item.label}</a>
              </li>
            );
          } else if (item.isEmber) {
            if (item.link === 'https://www.zoocasa.com/blog/') {
              if (isZoocasaTenant) {
                return (
                  <li key={index}>
                    <a href={item.link} data-testid={item.label.replace(/\s+/g, '') + testIds.menuDropdownOption}>{item.label}</a>
                  </li>
                );
              }
              return null;
            }
            return (
              <li key={index}>
                <a onClick={item.label == 'Edit Profile' ? () => trackEvent('UiProfilepView') : () => {}} href={item.link} data-testid={item.label.replace(/\s+/g, '') + testIds.menuDropdownOption}>{item.label}</a>
              </li>
            );
          } else if (item.label === 'Accessibility') {
            return (
              <li key={index} tabIndex={0}>
                <a onClick={handleAccessibilityClick} data-testid={item.label.replace(/\s+/g, '') + testIds.menuDropdownOption}>{item.label}</a>
              </li>
            );
          } else if (item.label === EXP_MENU_LINK_LABEL_SIGN_IN) {
            return (
              <li key={index}>
                {isAuthenticated ?
                  <a onClick={signOut}>Sign Out</a>
                  :
                  <a onClick={() => openModal('login-registration')}>Sign In</a>
                }
              </li>
            );
          } else if (item.label === EXP_MENU_LINK_LABEL_MY_SEARCHES) {
            return (
              !isAuthenticated && !isZoocasaTenant ?
                <></>
                :
                <li key={index}>
                  {!isAuthenticated ?
                    <a onClick={() => openModal('login-registration', {
                      callback: () => router.push(item.link as string),
                    })}>
                    My Searches
                    </a>
                    :
                    <Link href={item.link as string}>
                    My Searches
                    </Link>
                  }
                </li>
            );
          } else if (item.mobileOnly) {
            return (<div key={index} className={styles.mobile}></div>);
          } else if (item.link === '/newhomes') {
            if (features.usePreCon) {
              return (
                <li key={index}>
                  <Link href={item.link || '/'} onClick={() => trackEvent('precon-nav-click')} testId={'newConstruction' + testIds.menuDropdownOption}>New Construction</Link>
                </li>
              );
            } else {
              return null;
            }
          } else if (item.label === 'Chat') {
            return (
              <li key={index}>
                <a onClick={() => openChat({ source: 'Menu' })} data-testid={item.label.replace(/\s+/g, '') + testIds.menuDropdownOption}>{item.label}</a>
              </li>
            );
          } {
            return (
              <li key={index}>
                <Link href={item.link || '/'} target={item.newTab && '_blank'} testId={item.label.replace(/\s+/g, '') + testIds.menuDropdownOption}>{translate(item.label, siteLocation)}</Link>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}
