import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { buildClassName } from 'utils/build-class-name';
import Link from 'components/link';
import { useUserContext } from 'contexts/user';
import { IModalContext, ModalContext } from 'contexts/modal';
import MenuButton from 'components/header/menu-button';
import ThemedIcon from 'components/themed-icon';
import dataJSON from './data.json';
import FakeAdvancedSearch from 'components/search-bar/advanced-search/fake-search';
import styles from './style.module.scss';
import { headerIds, testIds } from 'constants/test-constants';
import { useIsDesktop, useIsMobile } from 'hooks/use-size-class';
import { useFeaturesContext, useThemeContext } from 'contexts';
import Button from 'components/control/button';
import UserIcon from 'components/icon/user-icon';
import { PATHS_WITH_FLOATING_HEADERS, PATHS_WITH_SCROLLED_HEADERS } from 'constants/ui-headers';
import AdvancedSearch from 'components/search-bar/advanced-search';
import SquareSearchIcon from 'components/icon/square/search-icon';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import { usePreferencesContext } from 'contexts/preferences';
import { useTrackExpOnlyEvents } from 'hooks/use-track-exp-only-events';
import { GTM_EXP_FIND_YOUR_HOME_CLICK_HOME_PAGE, GTM_EXP_FIND_YOUR_HOME_CLICK_UNIVERSAL } from 'constants/events';
import ImmersiveSearch from 'components/search-bar/immersive-search';
import { ThemeNames } from 'types/themes';
import Dropdown from 'components/dropdown';

const Header = () => {
  const { userLinks } = dataJSON;
  const { isAuthenticated, user, isCrawler } = useUserContext();
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { isSearchPanelOpen, setIsSearchPanelOpen } = useFeaturesContext();
  const { themeName: tenantName, theme } = useThemeContext();
  const { setIsMapSearchOpen, isMapSearchOpen } = usePreferencesContext();
  const [menuDropdownIsActive, setMenuDropdownIsActive] = useState(isCrawler);
  const [userDropdownIsActive, setUserDropdownIsActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const isMobile = useIsMobile();
  const router = useRouter();
  const { trackExpEvent } = useTrackExpOnlyEvents();
  const isFloating = PATHS_WITH_FLOATING_HEADERS.includes(router.pathname);
  const useScrolledHeader = PATHS_WITH_SCROLLED_HEADERS.includes(router.pathname);

  const isAgentSearch = router.pathname === '/agents-search';
  const isHomePage = router.pathname === '/';
  const isSearchPage = router.pathname && router.pathname.startsWith('/search');
  const isExpUsTenant = tenantName === ThemeNames.EXP_REALTY_US;
  const isExpCaTenant = tenantName === ThemeNames.EXP_REALTY_CA;
  const isAreaPage = router.pathname == '/[...dynamic]';
  const isJoinExp = tenantName === ThemeNames.EXP_REALTY_US && router.pathname.startsWith('/join-exp');
  const isJoinExpUs = tenantName === ThemeNames.EXP_REALTY_US && router.pathname.startsWith('/join-exp-us');
  const isJoinExpCa = tenantName === ThemeNames.EXP_REALTY_CA && router.pathname.startsWith('/join-exp-ca');
  const isDesktop = useIsDesktop();
  
  const searchSectionOutsideClickHandler = useOutsideClickHandler<HTMLDivElement>(() => { setIsSearchPanelOpen(false); setIsMapSearchOpen(false); });

  const toggleMenuDropdown = () => {
    setMenuDropdownIsActive(prev => !prev);
  };
  const toggleUserDropdown = () => {
    setUserDropdownIsActive(prev => !prev);
  };

  const generateAuthUserLabel = () => {
    if (user) {
      if (user.firstName || user.lastName) {
        return `${(user?.firstName || '').slice(0, 1)}${(user?.lastName || '').slice(0, 1)}`;
      }
      return user?.email?.slice(0, 1);
    }
    return 'Log In';
  };
  const authUserLabel = generateAuthUserLabel();

  const handleScroll = () => setIsScrolled(window.scrollY > 40);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleSearch = () => {
    if (isMapSearchOpen) {
      setIsMapSearchOpen(false);
    } else {
      const newValue = !isSearchPanelOpen;
      setIsSearchPanelOpen(newValue);
    }
  };

  const SearchOrAreaPageButton = () => (
    <div className={styles.search} ref={searchSectionOutsideClickHandler}>
      <Button
        Icon={isMobile && SquareSearchIcon}
        onClick={toggleSearch}
        className={buildClassName(styles.auth, isMobile && styles.user, (isSearchPanelOpen || isMapSearchOpen) && styles.active)}
      />
      {isMapSearchOpen && !isSearchPanelOpen && isExpUsTenant && (
        <div className={buildClassName(styles.panel, styles['no-background-color'])} onClick={() => { setIsSearchPanelOpen(true); setIsMapSearchOpen(false); }}>
          <FakeAdvancedSearch />
        </div>)}
      {isSearchPanelOpen && AdvancedSearch && (
        <div className={styles.panel}>
          <AdvancedSearch isInPanel={true} isFilterButtonHidden={true} hideSearchPanel={() => setIsSearchPanelOpen(false)} />
          <div className={styles['full-screen-search']} />
        </div>
      )}
    </div>
  );

  const ImReadyButton = () => (
    <Link href={'https://joinapp.exprealty.com/link/can'} target='_blank' className={styles['find-your-home']}>
      <Button label={'I\'m Ready'} className={styles.search} 
      />
    </Link>
  );

  const JoinExpContent = ({ url }: { url?: string }) => (
    <Link href={url || 'https://join.exprealty.com/contact/'} className={styles['find-your-home']}>
      <Button tabIndex={-1} label="Contact Us" className={styles.search} />
    </Link>
  );
  
  const DefaultContent = () => (
    <Link onClick={() => {
      if (isHomePage) {
        trackExpEvent(GTM_EXP_FIND_YOUR_HOME_CLICK_HOME_PAGE);
        trackExpEvent(GTM_EXP_FIND_YOUR_HOME_CLICK_UNIVERSAL);  
      } else {
        trackExpEvent(GTM_EXP_FIND_YOUR_HOME_CLICK_UNIVERSAL);  
      }
    }} 
    href={isExpUsTenant ? '/search?openSearch=true' : '/search'} className={styles['find-your-home']}>
      <Button tabIndex={-1} label="Find Your Home" className={styles.search} />
    </Link>
  );

  const isSearchOrAreaPage = (isSearchPage || isAreaPage);

  const HeaderButton = () => {
    if (isSearchOrAreaPage && isMobile) {
      return <SearchOrAreaPageButton />;
    } else if (isJoinExp || isJoinExpUs || isJoinExpCa) {
      return <JoinExpContent url="https://join.exprealty.com/contact-ca/"/>;
    } else {
      return <DefaultContent />;
    }
  };  

  const renderHeaderOrSearchBar = () => {
    if (isDesktop) {
      if (isSearchOrAreaPage) return null;
      if ((isExpCaTenant || isExpUsTenant) && (!isJoinExpUs && !isJoinExpCa)) return <ImmersiveSearch />;
    }
    return <HeaderButton />;
  };

  return (
    <div className={buildClassName(styles.component, isFloating && styles.home, isAgentSearch && styles['agent-search'], (isScrolled || useScrolledHeader) && styles.scrolled)} data-testid={headerIds.header}>
      <div className={buildClassName(styles['layout-container'])}>
        <div className={buildClassName(styles['logo-wrapper'])} data-testid={headerIds.zoocasaHeaderLogo}>
          <Link href="/" as="/" className={styles.logo} rel="noreferrer"><ThemedIcon /></Link>
        </div>
        <div className={styles.navigation}>
          {(isJoinExpUs || isJoinExpCa) ? <ImReadyButton /> : null}
          {renderHeaderOrSearchBar()}
          {!(isJoinExpUs || isJoinExpCa) && (isAuthenticated
            ?
            <MenuButton onClick={toggleUserDropdown} closeMenu={() => setUserDropdownIsActive(false)}>
              <span data-testid={headerIds.loggedInUser}>{authUserLabel} {userDropdownIsActive && Dropdown && <Dropdown items={userLinks} />}</span>
            </MenuButton>
            :
            <Button
              label={!isMobile ? authUserLabel : ''}
              Icon={isMobile && UserIcon}
              onClick={() => openModal('login-registration')}
              className={buildClassName(styles.auth, isMobile && styles.user)}
            />
          )}
          <MenuButton className={styles.menu} testId={testIds.menuButton} onClick={toggleMenuDropdown} closeMenu={() => setMenuDropdownIsActive(false)}>
            <>
              <div className={styles['bar-container']} id="menu">
                <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
                <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
              </div>
              {menuDropdownIsActive && Dropdown && <Dropdown items={theme.menuLinks} />}
            </>
          </MenuButton>
        </div>
      </div>
    </div>
  );
};

export default Header;
